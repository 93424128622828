



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import Datepicker from "vuejs-datepicker";
import scrollPagination from "vue-infinite-loading";
import moment from "moment";
@Component({ components: { HollowDotsSpinner, Datepicker, scrollPagination } })
export default class DashboardComponent extends Vue {
  public notifications = [];
  public dotLoader = false;
  public loading = false;
  public hollowLoader = false;
  public nextItem = 0;
  public callLogs = [];
  public borrowerNotificationCount = 0;
  public attachment = null;
  public fileType = null;
  public showNext = false;
  public showPrev = false;
  public fromDate: any = null;
  public toDate: any = null;
  public state: any = {};
  public fileArray = [];
  public fileToDisplay = "";
  public fileIndex = 0;
  public fileName = "";
  public searchString = null;
  public notificationLoanTxnId = "";
  public notificationTime = "";
  public disable = {};
  public toName = [];
  public notificationLoader = false;
  public loadMoreScroll = false;
  public hideLoadMore: Boolean = null;
  public initialPagination = { start: 0, end: 10 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public async searchByDate() {
    try {
      this.searchString = " ";
      this.searchString = null;
      this.searchString = "";
      let date = this.state.from.getDate();
      let month = this.state.from.getMonth();
      let year = this.state.from.getFullYear();
      this.state.from = new Date(year, month, date);
      let from = this.state.from;
      let date1 = this.state.to.getDate();
      let month1 = this.state.to.getMonth();
      let year1 = this.state.to.getFullYear();
      this.state.to = new Date(year1, month1, date1);
      let to = this.state.to;
      this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.getSuperAdminNotification(from, to, false);
    } catch (error) {
      console.log(error);
    }
  }
  public async getSuperAdminNotification(from, to, loadMore) {
    loadMore ? (this.loadMoreScroll = true) : (this.notificationLoader = true);
    try {
      let body = {
        superAdminCall: true,
        userId: this.$userId,
        userType: this.$userType,
        limitValue: this.nextItem,
        from: from.getTime(),
        to: to.getTime(),
        searchString: this.searchString,
        isPagination: this.pagination
      };
      let response = await Axios.post(
        BASE_API_URL + "message/getSuperAdminNotification",
        {
          body
        });
      this.borrowerNotificationCount =response.data.totalCount;
      this.pagination = response.data.pagination;
      if (
        response.data.data.length == 0 ||
        response.data.data.length < this.pagination.end - this.pagination.start
      ) {
        this.hideLoadMore = true;
      } else {
        this.hideLoadMore = false;
      }
      if (!loadMore) {
        this.notifications = response.data.data;
      } else {
        this.notifications = this.notifications.concat(response.data.data);
      }

      loadMore
        ? (this.loadMoreScroll = false)
        : (this.notificationLoader = false);
    } catch (error) {
      loadMore
        ? (this.loadMoreScroll = false)
        : (this.notificationLoader = false);
      console.log(error);
    }
  }

  public async changeNotificationisReadStatus(notificationId) {
    try {
      this.notifications = this.notifications.filter(
        e => e._id != notificationId
      );

      this.notifications = JSON.parse(JSON.stringify(this.notifications));
      let unReadedCommunications = [notificationId];
      let response = await Axios.post(
        BASE_API_URL + "communication/changeNotificationisReadStatus",
        {
          unReadedCommunications: unReadedCommunications
        });
      this.pagination=JSON.parse(JSON.stringify(this.initialPagination));
      this.getSuperAdminNotification(this.state.from, this.state.to, false);
      this.borrowerNotificationCount--;
    } catch (error) {
      console.log(error.data);
    }
  }

  public async viewPreview(files) {
    try {
   this.$refs.df["setInitialValuesForLocalFiles"](files, true, "file");
    } catch (error) {
      console.log(error);
    }
  }
  public hideDisplayFileModal() {
    this.dotLoader = false;
    this.$modal.hide("displayFile");
  }

  public downloadFile(file) {
    window.open(file);
  }
  mounted() {
    let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    this.state.from = new Date(year, month, date);
    this.state.to = new Date();

    this.disable = {
      from: this.state.from,
      to: this.state.to
    };
    this.getSuperAdminNotification(this.state.from, this.state.to, false);
  }
}
